import { log } from 'shared'
import { Range, RegulationSearchParams, RegulationSearchReturn } from 'types'
import { isBadResponseStatus } from 'api/api.helpers'
import apiClient from '../../apiClient'

type Options = {
  params: RegulationSearchParams
  range?: Range
}

export const getRegulationsBySearchTerm = async (
  options?: Options
): Promise<RegulationSearchReturn | null> => {
  const { range } = options || {}
  const session = await apiClient.auth.getSession()
  const onlineUserId = session.data.session?.user.id

  log('🔍 API: getRegulationsBySearchTerm', { onlineUserId })

  let query = apiClient
    .rpc('get_search_item', options?.params)
    .select(`id, title, date, fulltext, document_type, url, source, jurisdiction, authority`)
    .order('date', { ascending: false })
    .limit(10)

  if (range) {
    query = query.range(range.from, range.to)
  }

  const { data, error, status } = await query

  if (isBadResponseStatus(status)) {
    throw new Error(`getRegulationsBySearchTerm failed with status: ${status} and error: ${error}`)
  }

  if (!data) {
    return null
  }

  return data
}
