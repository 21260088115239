import { log } from 'shared'

import { isBadResponseStatus } from 'api/api.helpers'
import apiClient from '../../apiClient'
import { Database } from 'api/api.types'
import { Keys } from 'config'
import { RegulationSearchParams } from 'types'

type RegulationTable = Database['public']['Tables'][Keys.Regulations]

export const getRegulationsCount = async (params: RegulationSearchParams): Promise<number> => {
  log('🔍 API: getRegulationsCount')

  const query = apiClient
    .from<Keys.Regulations, RegulationTable>(Keys.Regulations)
    .select('title', { count: 'exact' })

  if (query && Object.values(params).length) {
    const mapFilterToTableColumn: Partial<
      Record<keyof RegulationSearchParams, keyof RegulationTable['Row']>
    > = {
      filter_authority: 'authority',
      filter_document_type: 'document_type',
      filter_jurisdiction: 'jurisdiction',
      filter_source: 'source'
    }

    Object.entries(params).forEach(([key, value]) => {
      if (!value || key === 'search_titlefulltext') {
        return
      }

      query.in(mapFilterToTableColumn[key], Array.isArray(value) ? value : [value])
    })
  }

  const { count, error, status } = await query

  if (isBadResponseStatus(status)) {
    throw new Error(`getRegulationsCount failed with status: ${status} and error: ${error}`)
  }

  return count ?? 0
}
