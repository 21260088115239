import { Database } from 'api/api.types'

export type BusinessUnit = {
  id: string
  name: string
}

export type CaseBusinessUnit = { businessUnitId: string; businessUnitName: string }

export type CaseBusinessUnits = Array<CaseBusinessUnit>

export type Controversy = {
  abstract?: string
  date?: string
  documentType?: string
  fulltext: string
  id: string
  relevant?: boolean | undefined
  fingerprints?: {
    fingerprintId: string
    fingerprintName: string
    labelNames: string
  }[]
  source?: string
  title: string
  url?: string
}

export enum CaseStatus {
  Closed = 'Closed',
  InProgress = 'In Progress',
  New = 'New',
  NotRelevant = 'Not Relevant',
  Review = 'Review'
}

export type CaseLink = { name: string; url: string }

export type Case = {
  controversy: string
  createdAt: string
  fingerprint?: string
  id: string
  links?: Array<CaseLink>
  name?: string
  reference?: string
  status: CaseStatus
  updatedAt: string
}

export type Color = {
  active: string
  id: string
  passive: string
}

export type Delabeling = {
  creator: string
  labeling: string
}

export type Document = {
  createdAt: string
  description?: string
  id: string
  name: string
  url: string
}

export type Fingerprint = {
  creator: string
  id: string
  name: string
  team: string
}

export type Label = {
  color: Color
  creator: string
  fingerprint: string
  id: string
  name: string
  team?: string
}

export type Labeling = {
  ai?: boolean
  controversyId: string
  color: Color
  creator: string
  end: number
  fingerprintId: string
  fingerprintName: string
  id: string
  labelId: string
  labelName: string
  paragraph?: number
  start: number
  text?: string
  title?: string
}

export type Note = {
  createdAt: string
  id: string
  text: string
}

export type Range = {
  from: number
  to: number
}

export type Relevance = {
  controversy: string
  user: string
  relevant: boolean
}

export type SourceStatus = 'disabled' | 'enabled' | 'new' | 'requested'

export type Source = {
  category?: string
  description?: string
  id: string
  identifier?: string
  language?: string
  link?: string
  name: string
  scope?: string
  status?: string
  sourceType?: string
}

type Functions = Database['public']['Functions']

// utility type to match the actual behavior
type NullableVal<T> = Record<keyof T, T[keyof T] | null>

export type RegulationSearchParams = NullableVal<Functions['get_search_item']['Args']>
export type RegulationSearchReturn = Functions['get_search_item']['Returns']

export type Regulation = Database['public']['Tables']['regulations']['Row']
