import styled, { keyframes } from 'styled-components'
import { BorderRadius, Colors, ControlSizes, FontSizes, Timing } from 'theme'

export const Label = styled.span`
  color: #fff;
  font-size: ${FontSizes.BaseButton};
  font-weight: 600;
  transition: ${Timing.TransitionControlElements};
`
const rotation = keyframes`
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
`

export const LoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`

export const Loader = styled.div<{ $passive?: boolean }>`
  animation: ${rotation} 1s linear infinite;
  border: 2px solid
    ${({ $passive }) => ($passive ? Colors.ButtonPrimaryBg : 'rgba(255, 255, 255, 1)')};
  border-bottom-color: rgba(255, 255, 255, 0);
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  height: 1rem;
  width: 1rem;
`

export const Wrapper = styled.button<{
  $disabled?: boolean
  $hasLeftIcon?: boolean
  $hollow?: boolean
  $passive?: boolean
  $size: 'base' | 'large'
  $wide?: boolean
}>`
  align-items: center;
  background: ${({ $hollow, $passive }) =>
    $passive ? Colors.ButtonSecondaryBg : $hollow ? 'transparent' : Colors.ButtonPrimaryBg};
  border-radius: ${BorderRadius.Controls};
  border: ${({ $hollow }) => ($hollow ? `1px solid ${Colors.TextLight}` : 'none')};
  display: flex;
  height: ${({ $size }) => ($size === 'large' ? ControlSizes.Large : ControlSizes.Base)};
  justify-content: center;
  padding: ${({ $hasLeftIcon, $size }) =>
    $size === 'large'
      ? `0 2em 0 ${$hasLeftIcon ? '1.75em' : '2em'}`
      : `0 1.5em 0 ${$hasLeftIcon ? '1.25em' : '1.5em'}`};
  width: ${({ $wide }) => ($wide ? '100%' : 'auto')};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'all')};
  position: relative;
  ${Label} {
    color: ${({ $hollow, $passive }) =>
      $passive
        ? Colors.ButtonSecondaryColor
        : $hollow
        ? Colors.TextLight
        : Colors.ButtonPrimaryColor};
    font-size: ${({ $size }) => ($size === 'large' ? FontSizes.LargeButton : FontSizes.BaseButton)};
  }

  svg {
    fill: ${({ $hollow, $passive }) =>
      $passive
        ? Colors.ButtonSecondaryColor
        : $hollow
        ? Colors.TextLight
        : Colors.ButtonPrimaryColor};
  }

  &:hover {
    background: ${({ $hollow, $passive }) =>
      $passive
        ? Colors.ButtonSecondaryHoverBg
        : $hollow
        ? 'transparent'
        : Colors.ButtonPrimaryHoverBg};
    border: ${({ $hollow }) => ($hollow ? `1px solid ${Colors.Text}` : 'none')};
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};

    ${Label} {
      color: ${({ $hollow, $passive }) =>
        $hollow || $passive ? Colors.ButtonSecondaryColor : Colors.ButtonPrimaryColor};
    }

    svg {
      fill: ${({ $hollow, $passive }) =>
        $hollow || $passive ? Colors.ButtonSecondaryColor : Colors.ButtonPrimaryColor};
      stroke: ${({ $hollow, $passive }) =>
        $hollow || $passive ? Colors.ButtonSecondaryColor : Colors.ButtonPrimaryColor};
    }
  }

  ${LoadingWrapper} {
    background: ${({ $hollow, $passive }) =>
      $passive ? Colors.Secondary : $hollow ? 'transparent' : Colors.Primary};
  }
`
