import React, { FC, forwardRef, ForwardedRef } from 'react'

import { Label, HiddenCheckbox, StyledCheckbox, Check } from './Checkbox.styles'

type InputElementType = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

type CheckboxProps = Pick<InputElementType, 'onChange' | 'checked' | 'id'> & {
  label: string
  className?: string
}

export const Checkbox: FC<CheckboxProps> = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ onChange, checked, label, className, ...rest }, ref: ForwardedRef<HTMLInputElement>) => {
    const id = label.replaceAll(' ', '-').toLowerCase()

    return (
      <Label htmlFor={id} className={className} checked={checked}>
        <HiddenCheckbox onChange={onChange} checked={checked} {...rest} ref={ref} id={id} />
        <StyledCheckbox checked={checked}>{checked && <Check size={16} />}</StyledCheckbox>
        {label}
      </Label>
    )
  }
)
