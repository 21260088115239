import { useQuery } from '@tanstack/react-query'
import { getRegulationsCountBySearchTerm } from 'api'
import { Keys } from 'config'
import { getDefaultFetcherOptions } from 'data-fetcher/data-fetcher.helpers'
import { FetcherOptions } from 'data-fetcher/data-fetcher.types'
import { RegulationSearchParams } from 'types'

export const useGetRegulationsCountBySearchTerm = (
  params: RegulationSearchParams,
  options?: FetcherOptions
) => {
  return useQuery(
    [
      Keys.Regulations,
      params
        ? Object.entries(params)
            .map(([key, value]) => `${key}-${value}`)
            .join('-')
        : null,
      'count'
    ],
    () => getRegulationsCountBySearchTerm({ params }),
    {
      ...getDefaultFetcherOptions(options),
      enabled: Boolean(params.search_titlefulltext) && params.search_titlefulltext !== ''
    }
  )
}
