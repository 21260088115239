import { TextInput } from 'components'
import { Root, ListContainer, Count, ListItem, StyledCheckbox } from './FilterList.styles'
import { FC, useEffect, useRef, useState } from 'react'

type FilterOption = { term: string; count: number }

type FilterListProps = {
  onSuggestionClick?: (suggestion: string) => void
  options?: FilterOption[] | null
  activeOptions?: string[]
  title?: string
}

export const FilterList: FC<FilterListProps> = ({
  activeOptions,
  onSuggestionClick,
  options,
  title
}) => {
  const [input, setInput] = useState('')
  const [suggestions, setSuggestions] = useState<FilterOption[] | null>()

  const listContainerRef = useRef<HTMLUListElement>(null)
  const [isScrolledToTop, setIsScrolledToTop] = useState(true)
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const container = listContainerRef.current
      if (!container) {
        return
      }

      setIsScrolledToBottom(container.scrollHeight - container.scrollTop === container.clientHeight)
      setIsScrolledToTop(container.scrollTop === 0)
    }

    const container = listContainerRef.current
    if (container) {
      container.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  const handleChange = (text: string) => {
    setInput(text)

    if (!options) {
      return
    }

    const filteredSuggestions = options.filter(option =>
      option.term.toLowerCase().includes(text.toLowerCase())
    )

    setSuggestions(filteredSuggestions)
  }

  const listItems = suggestions && Object.keys(suggestions).length ? suggestions : options

  const sortedListItems = listItems?.sort((a, b) => {
    const isAActive = activeOptions?.includes(a.term)
    const isBActive = activeOptions?.includes(b.term)

    // Sort by whether the item is active
    if (isAActive && !isBActive) {
      return -1
    } else if (!isAActive && isBActive) {
      return 1
    } else {
      // If both are active or inactive, maintain their original order
      return 0
    }
  })

  const handleOptionClick = (term: string) => {
    // TODO: Re-add if automatic scrolling is desired
    // document.getElementById('content')?.scrollTo({ top: 0, behavior: 'smooth' })

    onSuggestionClick?.(term)
  }

  const isListScrollable = Boolean(
    listContainerRef.current?.clientHeight && listContainerRef?.current?.clientHeight >= 160
  )

  return (
    <Root>
      <TextInput onChange={handleChange} value={input} placeholder={`Filter ${title}`} />
      <ListContainer
        ref={listContainerRef}
        $showShadowTop={isListScrollable && !isScrolledToTop}
        $showShadowBottom={isListScrollable && !isScrolledToBottom}
        $height={listContainerRef.current?.clientHeight}
        layoutRoot
      >
        {sortedListItems?.map(({ term, count }) => (
          <ListItem key={term} layout>
            <StyledCheckbox
              checked={activeOptions?.includes(term)}
              id={term}
              onChange={() => handleOptionClick(term)}
              label={term}
            />
            <Count>{count}</Count>
          </ListItem>
        ))}
      </ListContainer>
    </Root>
  )
}
