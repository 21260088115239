import { useQuery } from '@tanstack/react-query'
import { getFilterOptions } from 'api'
import { Keys } from 'config'
import { getDefaultFetcherOptions } from '../../data-fetcher.helpers'
import { FetcherOptions } from '../../data-fetcher.types'
import { Regulation, RegulationSearchParams } from 'types'

export const useGetFilterOptions = (
  params: RegulationSearchParams,
  filterKey: keyof Regulation,
  options?: FetcherOptions
) => {
  return useQuery(
    [
      Keys.Regulations,
      params
        ? Object.entries(params)
            .map(([key, value]) => `${key}-${value}`)
            .join('-')
        : null,
      filterKey
    ],
    () => getFilterOptions({ params, filter: filterKey }),
    {
      ...getDefaultFetcherOptions(options)
    }
  )
}
