import { log } from 'shared'
import { Range, RegulationSearchParams, RegulationSearchReturn } from 'types'
import { isBadResponseStatus } from 'api/api.helpers'
import apiClient from '../../apiClient'
import { Database } from 'api/api.types'
import { Keys } from 'config'

type Options = {
  params: RegulationSearchParams
  range?: Range
}

type RegulationTable = Database['public']['Tables'][Keys.Regulations]

export const getRegulations = async (options?: Options): Promise<RegulationSearchReturn | null> => {
  const { range, params } = options || {}
  const session = await apiClient.auth.getSession()
  const onlineUserId = session.data.session?.user.id

  log('🔍 API: getRegulations', { onlineUserId })

  let query = apiClient
    .from<Keys.Regulations, RegulationTable>(Keys.Regulations)
    .select(`id, title, date, fulltext, document_type, url, source, jurisdiction, authority`)

  if (params && Object.values(params).length) {
    const mapFilterToTableColumn: Partial<
      Record<keyof RegulationSearchParams, keyof RegulationTable['Row']>
    > = {
      filter_authority: 'authority',
      filter_document_type: 'document_type',
      filter_jurisdiction: 'jurisdiction',
      filter_source: 'source'
    }

    Object.entries(params).forEach(([key, value]) => {
      if (!value) {
        return
      }

      query.in(mapFilterToTableColumn[key], Array.isArray(value) ? value : [value])
    })
  }

  query.order('date', { ascending: false }).limit(10)
  if (range) {
    query = query.range(range.from, range.to)
  }

  const { data, error, status } = await query

  if (isBadResponseStatus(status)) {
    throw new Error(`getRegulationsBySearchTerm failed with status: ${status} and error: ${error}`)
  }

  if (!data) {
    return null
  }

  return data
}
