enum Colors {
  Base = '#f5f6fa',
  ButtonPrimaryBg = '#061c6c',
  ButtonPrimaryColor = '#fff',
  ButtonPrimaryHoverBg = '#04175B',
  ButtonSecondaryBg = '#E3E6EE',
  ButtonSecondaryColor = '#111',
  ButtonSecondaryHoverBg = '#D6DAE7',
  Destructive = '#C53030',
  DestructiveBackground = '#FED7D7',
  Divider = 'rgba(0, 0, 0, 0.09)',
  InputBorder = 'rgba(0, 0, 0, 0.15)',
  Navigation = '#10131f',
  Placeholder = 'rgba(0, 0, 0, 0.2)',
  Primary = '#061c6c',
  PrimaryHover = '#04175B',
  PrimaryLight = '#CFD7F3',
  PrimaryLightHover = '#BDC5E1',
  Secondary = '#E8EBF4',
  SecondaryHover = '#D6DAE7',
  Success = '#2F855A',
  SuccessBackground = '#C6F6D5',
  Surface = '#fff',
  Text = '#111',
  TextHover = '#000',
  TextLight = '#6B7280',
  TextLightHover = '#4B515C'
}

export default Colors

export const labelColors = [
  {
    active: '#1A365D',
    passive: '#90CDF4'
  },
  {
    active: '#2C5282',
    passive: '#BEE3F8'
  },
  {
    active: '#3182CE',
    passive: '#EBF8FF'
  },
  {
    active: '#1D4044',
    passive: '#81E6D9'
  },
  {
    active: '#285E61',
    passive: '#B2F5EA'
  },
  {
    active: '#319795',
    passive: '#E6FFFA'
  },
  {
    active: '#22543D',
    passive: '#9AE6B4'
  },
  {
    active: '#48BB78',
    passive: '#F0FFF4'
  },
  {
    active: '#44337A',
    passive: '#D6BCFA'
  },
  {
    active: '#6B46C1',
    passive: '#E9D8FD'
  },
  {
    active: '#9F7AEA',
    passive: '#FAF5FF'
  },
  {
    active: '#2F855A',
    passive: '#C6F6D5'
  },
  {
    active: '#086F83',
    passive: '#9DECF9'
  },
  {
    active: '#00A3C4',
    passive: '#C4F1F9'
  },
  {
    active: '#1A202C',
    passive: '#E2E8F0'
  },
  {
    active: '#4A5568',
    passive: '#EDF2F7'
  },
  {
    active: '#A0AEC0',
    passive: '#F7FAFC'
  },
  {
    active: '#822727',
    passive: '#FEB2B2'
  },
  {
    active: '#C53030',
    passive: '#FED7D7'
  },
  {
    active: '#F56565',
    passive: '#FFF5F5'
  }
]
