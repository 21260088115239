import {
  RiAlertLine,
  RiBookmarkLine,
  RiFunctionLine,
  RiBookLine,
  RiFingerprintLine,
	RiSearchLine
} from 'react-icons/ri'

const iconAttributes = { color: '#fff', size: 16 }

export const navigationItems = [
  {
    icon: <RiFunctionLine {...iconAttributes} />,
    label: 'Dashboard',
    to: '/dashboard'
  },
  {
    icon: <RiAlertLine {...iconAttributes} />,
    label: 'Controversies',
    to: '/controversies'
  },
  {
    icon: <RiSearchLine {...iconAttributes} />,
    label: 'Regulations',
    to: '/regulations'
  },
  {
    icon: <RiBookmarkLine {...iconAttributes} />,
    label: 'Cases',
    to: '/cases'
  },
  {
    icon: <RiFingerprintLine {...iconAttributes} />,
    label: 'Fingerprints',
    to: '/fingerprints'
  },
  {
    icon: <RiBookLine {...iconAttributes} />,
    label: 'Sources',
    to: '/sources'
  }
]
